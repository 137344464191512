import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
